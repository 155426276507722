import Cookies from 'js-cookie'
import { on } from 'delegated-events'

const widget = document.querySelector('.site-widget')

const setCookie = (state) => {
  Cookies.set('widgetstate', state, {
    expires: 1,
  })
}

const handleToggle = () => {
  const offset = widget.querySelector('.site-widget-top').offsetHeight

  if (widget.getAttribute('aria-expanded') === 'false') {
    widget.style.transform = 'translate3d(0, 0, 0)'
    widget.setAttribute('aria-expanded', 'true')
    setCookie('active')
  } else {
    widget.style.transform = `translate3d(0, calc(100% - ${offset}px), 0)`
    widget.setAttribute('aria-expanded', 'false')
    setCookie('inactive')
  }
}

const handleClose = (e) => {
  e.stopPropagation()

  widget.remove()
}

export default {
  init: () => {
    if (widget) {
      on('click', '.site-widget-top', handleToggle)
      on('click', '.site-widget-close', handleClose)

      // Always set to active if this setting is set
      if (widget.classList.contains('has-auto-hide-override-cookie')) {
        setCookie('active')
      }

      const offset = widget.querySelector('.site-widget-top').offsetHeight

      if (Cookies.get('widgetstate') === 'inactive') {
        setTimeout(() => {
          widget.style.transform = `translate3d(0, calc(100% - ${offset}px), 0)`
          widget.setAttribute('aria-expanded', 'false')
        }, 25)
        setCookie('inactive')
      } else {
        widget.classList.add('no-animate')
        widget.style.transform = 'translate3d(0, 0, 0)'
        widget.setAttribute('aria-expanded', 'true')
        setCookie('active')

        setTimeout(() => {
          widget.classList.remove('no-animate')
        }, 750)
      }

      if (widget.classList.contains('has-auto-hide')) {
        setTimeout(() => {
          if (widget.getAttribute('aria-expanded') === 'true') {
            widget.style.transform = `translate3d(0, calc(100% - ${offset}px), 0)`
            widget.setAttribute('aria-expanded', 'false')

            if (!widget.classList.contains('has-auto-hide-override-cookie')) {
              setCookie('inactive')
            }
          }
        }, 10000)
      }
    }
  },
}
