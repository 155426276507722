// Dynamically loads any components based on page conditions

const pageHas = (selector) => {
  return document.querySelectorAll(selector).length
}

const loadComponents = async () => {
  if (pageHas('[data-counter]')) {
    const { default: component } = await import(
      /* webpackChunkName: "components-count-up" */ '@scripts/components/count-up'
    )

    component.init()
  }

  if (pageHas('[data-paginated-list]')) {
    const { default: component } = await import(
      /* webpackChunkName: "components-paginated-list" */ '@scripts/components/paginated-list'
    )

    component.init()
  }

  if (pageHas('[data-slide-toggle]')) {
    const { default: component } = await import(
      /* webpackChunkName: "components-slide-toggle" */ '@scripts/components/slide-toggle'
    )

    component.init()
  }

  if (pageHas('[data-lazy-load]')) {
    const { default: component } = await import(/* webpackChunkName: "components-lazy" */ '@scripts/components/lazy')

    component.init()
  }

  if (pageHas('.add-to-calendar')) {
    const { default: component } = await import(
      /* webpackChunkName: "components-add-to-calendar" */ '@scripts/components/add-to-calendar'
    )

    component.init()
  }

  if (pageHas('[data-modal]')) {
    const { default: component } = await import(/* webpackChunkName: "components-modal" */ '@scripts/components/modal')

    component.init()
  }

  if (pageHas('.page-header-slider')) {
    const { default: component } = await import(
      /* webpackChunkName: "components-page-header-slider" */ '@scripts/components/page-header-slider'
    )

    component.init()
  }
}

export default {
  init: loadComponents,
}
