import Cookies from 'js-cookie'

export default {
  init: async () => {
    const overlayUrl = document.body.dataset.pageOverlayUrl
    const overlayCookie = document.body.dataset.pageOverlayCookie

    if (overlayUrl) {
      const { default: component } = await import(
        /* webpackChunkName: "components-modal" */ '@scripts/components/modal'
      )

      component.openModal({
        type: 'content',
        url: overlayUrl,
      })

      if (parseInt(overlayCookie) === 1) {
        Cookies.set('seenpageoverlay', true, { expires: 1 })
      }
    }
  },
}
