import { on } from 'delegated-events'

export default {
  init: () => {
    if (window.Adjusters.subsiteId !== 0) {
      return
    }

    if (window.Adjusters.contactRedirectNewTab) {
      const contactUrlSegment = window.Adjusters.contactPageUrlSegment

      on('click', `a[href*="${contactUrlSegment}"]`, (e) => {
        const url = new URL(e.currentTarget.href)

        if (url.pathname === contactUrlSegment) {
          e.preventDefault()

          window.open(url, '_blank')
        }
      })
    }
  },
}
