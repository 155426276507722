export const isTouchDevice = 'ontouchstart' in document.documentElement

export const isIos =
  /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

export const createUrl = (base, formData = {}, append = false) => {
  let data = formData

  if (formData instanceof FormData === false) {
    data = new FormData()

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value)
    }
  }

  if (typeof base === 'object') {
    const baseUrl = new URL(base)

    if (append) {
      const params = baseUrl.searchParams

      for (const [key, value] of data) {
        params.set(key, value)
      }

      return baseUrl
    } else {
      const params = new URLSearchParams(data)

      return new URL(`${base}?${params}`)
    }
  }

  if (typeof base === 'string') {
    const params = new URLSearchParams(formData)
    const url = new URL(`${base}?${params}`)

    return url
  }
}

export const geolocate = (success, error) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error, { maximumAge: 0 })
  } else {
    return false
  }
}

export const trackGoogleAnalytics = (event) => {
  if (window.dataLayer) {
    dataLayer.push(event)
  }
}
