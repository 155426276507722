import { on } from 'delegated-events'
import scrollTo from '@common/scroll-to'

const ignorePageTypes = []

const handleScroll = (event, el) => {
  let targetSelector = null

  if (el.dataset.scroll) {
    targetSelector = el.dataset.scroll
  } else if (el.tagName === 'A') {
    targetSelector = el.hash
  } else {
    return
  }

  if (targetSelector) {
    const target = document.querySelector(targetSelector)
    let offset = 0

    if (target) {
      event.preventDefault()

      // Deactivate navigation menu if active
      document.documentElement.classList.remove('js-nav-active')

      // If the target is inside a slide toggle, need to open it first
      const slideToggleItem = target.closest('[data-slide-toggle-item]')

      if (slideToggleItem) {
        const trigger = slideToggleItem.querySelector('[data-slide-toggle]')
        const target = document.getElementById(trigger.getAttribute('aria-controls'))

        if (trigger && target) {
          trigger.setAttribute('aria-expanded', 'true')
          target.style.display = 'block'
        }

        // Need some additional offset since these items probably don't have padding-top
        offset = 24
      }

      scrollTo(target, offset)
    }
  }
}

const handleUrl = () => {
  const shouldIgnore = Array.from(document.body.classList).some((x) => ignorePageTypes.includes(x))

  if (shouldIgnore) {
    return
  }

  if (window.location.hash) {
    let targetSelector = window.location.hash

    let target = document.querySelector(targetSelector)

    if (target) {
      scrollTo(target)
    }
  }
}

// Automatically scrolls to the first element with the [data-scrolltome] attribute
const handleScrollToMe = () => {
  const target = document.querySelector('[data-scrolltome]')

  if (target) {
    setTimeout(() => {
      scrollTo(target)
    }, 250)
  }
}

export default {
  init: () => {
    on('click', '[data-scroll]', (event) => {
      const el = event.target.closest('[data-scroll]')

      handleScroll(event, el)
    })

    // Handle any custom anchor tags
    on('click', '.wysiwyg a[href*="#"]', (event) => {
      const el = event.target.closest('a')

      handleScroll(event, el)
    })

    handleUrl()
    handleScrollToMe()
  },
}
