import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  init: () => {
    const variants = {
      'fade-up': {
        from: { opacity: 0, y: '50%' },
        to: { opacity: 1, y: 0 },
      },
      'fade-left': {
        from: { opacity: 0, x: '100%' },
        to: { opacity: 1, x: 0 },
      },
      'fade-right': {
        from: { opacity: 0, x: '-100%' },
        to: { opacity: 1, x: 0 },
      },
    }

    const elementSelector = '.element-section:not([data-disable-animations]) [data-sr-group]'

    // Handle elements
    for (const [key, opts] of Object.entries(variants)) {
      if (document.querySelectorAll(`${elementSelector} [data-sr="${key}"]`).length) {
        gsap.set(`${elementSelector} [data-sr="${key}"]`, opts.from)
      }
    }

    // Handle element content parts
    if (document.querySelectorAll(`${elementSelector} [data-sr="fade-content-up"]`).length) {
      const childrenNotWysiwyg = `${elementSelector} [data-sr="fade-content-up"] > *:not(.wysiwyg)`

      if (document.querySelectorAll(childrenNotWysiwyg).length) {
        gsap.set(childrenNotWysiwyg, {
          opacity: 0,
          y: '50%',
        })
      }

      const childrenNotChecklist = `${elementSelector} [data-sr="fade-content-up"] > .wysiwyg > *:not([class*="as-checklist"])`

      if (document.querySelectorAll(childrenNotChecklist).length) {
        gsap.set(childrenNotChecklist, {
          opacity: 0,
          y: '50%',
        })
      }

      const childrenChecklist = `${elementSelector} [data-sr="fade-content-up"] > .wysiwyg > [class*="as-checklist"] li`

      if (document.querySelectorAll(childrenChecklist).length) {
        gsap.set(childrenChecklist, {
          opacity: 0,
          y: '50%',
        })
      }
    }

    document.querySelectorAll('.element-section:not([data-disable-animations]) [data-sr-group]').forEach((group) => {
      const common = {
        duration: 0.8,
        stagger: 0.2,
        paused: true,
        ease: 'power4',
        scrollTrigger: {
          trigger: group,
          start: 'top 80%',
          markers: false,
        },
      }

      for (const [key, opts] of Object.entries(variants)) {
        const els = group.querySelectorAll(`[data-sr="${key}"]`)

        if (els.length) {
          gsap.to(els, {
            ...opts.to,
            ...common,
          })
        }
      }

      group.querySelectorAll('[data-sr="fade-content-up"]').forEach((content) => {
        gsap.to(content.querySelectorAll('*'), {
          opacity: 1,
          y: 0,
          ...common,
        })
      })
    })

    // Handle page header
    const pageHeader = document.querySelector('.page-header')

    if (pageHeader) {
      const actions = pageHeader.querySelector('.page-header-actions')
      const leadIn = pageHeader.querySelector('.page-header-lead-in')

      const common = {
        duration: 0.8,
        stagger: 0.2,
        paused: true,
        ease: 'power4',
        scrollTrigger: {
          trigger: pageHeader,
          start: 'top 80%',
          markers: false,
        },
      }

      if (leadIn && leadIn.querySelectorAll('[class*="as-checklist"]').length) {
        let selector = '.page-header [class*="as-checklist"] li'

        gsap.set(selector, { opacity: 0, y: '50%' })

        if (actions) {
          selector = `${selector}, .page-header-actions`
          gsap.set(actions, { opacity: 0, y: '50%' })
        }

        gsap.to(selector, {
          opacity: 1,
          y: 0,
          ...common,
        })
      }
    }

    window.addEventListener('beforeprint', () => {
      for (const [key, opts] of Object.entries(variants)) {
        if (document.querySelectorAll(`[data-sr="${key}"]`).length) {
          gsap.set(`[data-sr="${key}"]`, opts.to)
        }
      }
    })
  },
}
