import { on } from 'delegated-events'
import ajax from '@common/ajax'
import { trackGoogleAnalytics } from '@common/util'

const form = document.querySelector('.footer-subscribe-form')

const toggleSubmit = (submitButton) => {
  submitButton.disabled = !submitButton.disabled
  submitButton.classList.toggle('btn-loading')
}

const showFormMessages = (holder, message, success, autoHide) => {
  holder.style.display = 'block'
  holder.innerHTML = message

  if (success) {
    holder.classList.remove('form-message-error')
  } else {
    holder.classList.add('form-message-error')
  }

  if (autoHide) {
    setTimeout(() => {
      holder.style.display = 'none'
    }, 5000)
  }
}

const validate = (form) => {
  let valid = true

  if (!form.querySelectorAll('input[type="checkbox"]:checked').length) {
    return 'Please select at least one option'
  }

  if (!form.querySelectorAll('input[type="email"]:valid').length) {
    return 'Please enter an email address'
  }

  return valid
}

const handleAjaxSubmit = (e) => {
  e.preventDefault()

  const form = e.target

  const formMessages = form.querySelector('.form-messages')
  const submitButton = form.querySelector('[type=submit]')

  const isValid = validate(form)

  if (isValid !== true) {
    showFormMessages(formMessages, isValid)

    return
  }

  toggleSubmit(submitButton)

  const formData = new FormData(form)

  ajax
    .url(form.action)
    .body(formData)
    .post()
    .json((res) => {
      if (res.success) {
        if (res.ga) {
          res.ga.forEach((event) => trackGoogleAnalytics(event))
        }

        form.reset()
      }

      if (res.message) {
        showFormMessages(formMessages, res.message, res.success, true)
      }
    })
    .catch(() => {
      showFormMessages(formMessages, 'Sorry, there was a problem with your submission')
    })
    .finally(() => {
      toggleSubmit(submitButton)
    })
}

const handlePublication = async (e) => {
  e.preventDefault()

  const { default: component } = await import(/* webpackChunkName: "components-modal" */ '@scripts/components/modal')

  component.openModal({
    type: 'subscribe',
    url: '/_subscription/modal?type=all',
  })
}

export default {
  init: () => {
    if (form) {
      on('submit', '.footer-subscribe-form', handleAjaxSubmit)
      on('click', '.footer-subscribe-form .valApp_Publication_Publication-1', handlePublication)
    }
  },
}
