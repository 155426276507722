import jump from 'jump.js'

const getPageOffset = () => {
  let offset = 0

  const siteHeader = document.querySelector('.site-header')

  if (siteHeader) {
    offset += siteHeader.offsetHeight
  }

  return offset
}

const scrollTo = (target, additionalOffset = 0) => {
  const pageOffset = getPageOffset() * -1

  if (target.dataset.scrollOffset) {
    additionalOffset = parseInt(target.dataset.scrollOffset)
  }

  let offset = pageOffset - additionalOffset

  return new Promise((resolve) => {
    jump(target, { duration: 600, offset, callback: resolve })
  })
}

export default scrollTo
