import { on, fire } from 'delegated-events'
import ajax from '@common/ajax'

const menu = document.querySelector('.site-menu')
const siteMenuOpen = document.querySelector('.site-menu-open')

const loadHtml = async () => {
  const url = menu.dataset.url

  if (url) {
    const { default: slideToggle } = await import(
      /* webpackChunkName: "components-slide-toggle" */ '@scripts/components/slide-toggle'
    )

    slideToggle.init()

    const res = await ajax.get(url).text()

    const html = document.createRange().createContextualFragment(res)

    menu.querySelector('.site-menu-placeholder').replaceWith(html)

    menu.removeAttribute('data-url')

    fire(document.documentElement, 'sitemenu:load')

    // Automatically open the submenu(s) of the current page
    // Stop it from automatically closing on menu close
    if (siteMenuOpen && window.matchMedia('(min-width: 1024px)').matches) {
      const currentPageId = siteMenuOpen.dataset.currentPageId
      const menuItem = menu.querySelector(`[data-page-id="${currentPageId}"]`)

      if (menuItem) {
        const subMenu = menuItem.closest('.site-menu-submenu')

        if (subMenu) {
          const trigger = menu.querySelector(`[aria-controls="${subMenu.id}"]`)
          trigger.setAttribute('aria-expanded', 'true')
          trigger.parentNode.classList.add('is-active', 'ignore')

          subMenu.style.display = 'block'
        }
      }
    }
  }
}

const open = () => {
  loadHtml()
  document.documentElement.classList.add('js-site-menu-open')
}

const close = () => {
  document.documentElement.classList.remove('js-site-menu-open')

  menu.querySelectorAll('.is-active').forEach((el) => {
    if (!el.classList.contains('ignore')) {
      const trigger = el.querySelector('[data-slide-toggle]')
      const target = document.getElementById(trigger.getAttribute('aria-controls'))

      target.style = null
      trigger.setAttribute('aria-expanded', 'false')
      el.classList.remove('is-active')
    }
  })
}

export default {
  init: () => {
    if (menu) {
      const prefetchLink = document.createElement('link')
      prefetchLink.href = menu.dataset.url
      prefetchLink.rel = 'prefetch'
      document.head.appendChild(prefetchLink)

      on('click', '.site-menu-open', open)
      on('click', '.site-menu-close', close)
      on('click', '.site-menu-screen', close)

      // Clear ignore class so all submenus close
      on('click', '.site-menu .ignore [data-slide-toggle]', (e) => {
        e.target.closest('.ignore').classList.remove('ignore')
      })
    }
  },
}
