import { on } from 'delegated-events'
import ajax from '@common/ajax'

const data = {}

const setupWhatConverts = (wcId) => {
  const tag = document.querySelector('.what-converts-tag')

  if (tag) {
    tag.parentNode.removeChild(tag)
    const wc = '//scripts.iconnode.com/' + wcId + '.js'
    const wcScript = document.createElement('script')
    wcScript.setAttribute('src', wc)
    wcScript.classList.add('what-converts-tag')
    document.body.appendChild(wcScript)
    djteiyewvcuvyogyifuj()
  }
}

export default {
  init: async () => {
    if (document.body.classList.contains('page-subsite-0')) {
      // Handle corporate

      const res = await ajax.get('/_ajax/swapnumbers').json()

      data.phone = res.Phone
      data.phoneTel = res.PhoneTel
      data.wcId = res.WhatConvertsID

      document.querySelectorAll('.site-header .phone-link').forEach((anchor) => {
        anchor.textContent = data.phone
        anchor.setAttribute('href', 'tel:' + data.phoneTel)
      })

      document.querySelectorAll('.site-footer .phone-link').forEach((anchor) => {
        anchor.textContent = data.phone
        anchor.setAttribute('href', 'tel:' + data.phoneTel)
      })

      setupWhatConverts(data.wcId)

      on('sitemenu:load', 'html', () => {
        document.querySelectorAll('.site-menu .phone-link').forEach((anchor) => {
          anchor.querySelector('span').textContent = data.phone
          anchor.setAttribute('href', 'tel:' + data.phoneTel)
        })

        setupWhatConverts(data.wcId)
      })
    } else {
      // Handle everyone else

      // Need to call the what-converts function again to handle numbers in the lazy-loaded menu
      on('sitemenu:load', 'html', () => {
        const tag = document.querySelector('.what-converts-tag')

        if (tag) {
          djteiyewvcuvyogyifuj()
        }
      })
    }
  },
}
