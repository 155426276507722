// Styles
import '@styles/index.css'

// Thirdparty
// import { listen as quicklinkListen } from 'quicklink'

// Scripts
import components from '@scripts/components'
import contactRedirect from '@scripts/components/contact-redirect'
import mobileBar from '@scripts/components/mobile-bar'
import pageOverlay from '@scripts/components/page-overlay'
import scrollReveal from '@scripts/components/scroll-reveal'
import scrollToListener from '@scripts/components/scroll-to'
import siteMenu from '@scripts/components/site-menu'
import siteAnnouncementBar from '@scripts/components/site-announcement-bar'
import siteWidget from '@scripts/components/site-widget'
import footerSubscribeForm from '@scripts/components/footer-subscribe-form'
import swapNumbers from '@scripts/components/swap-numbers'

components.init()
contactRedirect.init()
mobileBar.init()
pageOverlay.init()
scrollReveal.init()
scrollToListener.init()
siteMenu.init()
siteAnnouncementBar.init()
siteWidget.init()
footerSubscribeForm.init()
swapNumbers.init()

document.addEventListener('DOMContentLoaded', () => {
  // quicklinkListen({
  //   ignores: [/\/_downloadfile\/?/, /\/_modal\/?/, /\/wp-content\/?/, (_, elem) => elem.hasAttribute('noprefetch')],
  // })

  setTimeout(() => {
    document.documentElement.classList.remove('_preload')
  }, 500)

  const siteHeader = document.querySelector('.site-header')

  if (siteHeader) {
    document.documentElement.style.setProperty('--site-header-height', `${siteHeader.offsetHeight}px`)
  }

  // Add download attribute to any anchor to a file with our custom button class
  document.querySelectorAll('.wysiwyg a.as-button-text-secondary[href^="/assets"]').forEach((anchor) => {
    anchor.download = ''
  })
})

/* Debugging layout shifts */
// let cls = 0
// new PerformanceObserver((entryList) => {
//   for (const entry of entryList.getEntries()) {
//     if (!entry.hadRecentInput) {
//       cls += entry.value
//       console.log('Current CLS value:', cls, entry)
//     }
//   }
// }).observe({ type: 'layout-shift', buffered: true })
